import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
    mode: 'history',
    routes: [
        {
            name: "home",
            path: "/",
            component: () => import("@/views/Home")
        },
        {
            name: "category",
            path: "/category/:alias",
            component: () => import("@/views/Category")
        },
        {
            name: "product",
            path: "/product/:alias",
            component: () => import("@/views/Product")
        }
    ]
});