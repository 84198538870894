<template>
  <div id="app">
    <Header />
    <router-view />
    <Footer />
  </div>
</template>

<style>
</style>

<style lang="scss">
// Import Bulma's core
@import "~bulma/sass/utilities/_all";

// Set your colors
$primary: #f7a31d;
$primary-invert: findColorInvert($primary);
$black: #000000;

// Setup $colors to use as bulma classes (e.g. 'is-twitter')
$colors: (
  "white": (
    $white,
    $black,
  ),
  "black": (
    $black,
    $white,
  ),
  "light": (
    $light,
    $light-invert,
  ),
  "dark": (
    $dark,
    $dark-invert,
  ),
  "primary": (
    $primary,
    $primary-invert,
  ),
  "info": (
    $info,
    $info-invert,
  ),
  "success": (
    $success,
    $success-invert,
  ),
  "warning": (
    $primary,
    $primary-invert,
  ),
  "danger": (
    $danger,
    $danger-invert,
  ),
);

// Links
$link: $black;
$link-invert: $primary;
$link-focus-border: $primary;

// Import Bulma and Buefy styles
@import "~bulma";
@import "~buefy/src/scss/buefy";

body,
html {
  font-family: "Comfortaa", cursive;
  height: 100%;
}
.title-custom {
  background: #837ea1;
  font-family: "Comfortaa", Sans-serif;
  font-weight: bold;
  font-size: 32px;
  text-transform: uppercase;
  color: #ffffff;
  text-align: center;
}
.subtitle-custom {
  color: #000;
  font-family: "Comfortaa", Sans-serif;
  text-align: center;
  font-weight: bold;
  font-size: 20px;
  padding: 15px 0;
}
#app {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
}
#app .section {
  width: 100%;
}
.tabs {
  white-space: break-spaces !important;
}
</style>

<script>
import Header from "./components/Header.vue";
import Footer from "./components/Footer.vue";

export default {
  components: { Header, Footer },
  name: "App",
};
</script>
