<template>
  <div class="header">
    <b-navbar class="navbar container" mobile-burger>
      <template #brand>
        <b-navbar-item>
          <img :src="logo" alt="Logo" style="height: 52px" />
        </b-navbar-item>
        <div
          class="headertext"
          style="
            font-size: 14px;
            font-weight: bold;
            text-align: center;
            padding-top: 8px;
            color: #000000;
          "
        >
          Продажа и обслуживание <br />кассовой техники
        </div>
      </template>

      <template #end>
        <b-navbar-item tag="a" href="https://kkt57.ru/">
          ГЛАВНАЯ
        </b-navbar-item>
        <b-navbar-item tag="a" href="https://kkt57.ru/o-nas/">
          О НАС
        </b-navbar-item>
        <b-navbar-dropdown label="УСЛУГИ" :collapsible="true">
          <b-navbar-item href="https://kkt57.ru/elektronnaya_podpis/">
            ЭЛЕКТРОННАЯ ПОДПИСЬ
          </b-navbar-item>
          <b-navbar-item href="https://kkt57.ru/servisnoe-obsluzhivanie/">
            СЕРВИСНОЕ ОБСЛУЖИВАНИЕ
          </b-navbar-item>
          <b-navbar-item href="https://kkt57.ru/markirovka-tovara/">
            ПОДКЛЮЧЕНИЕ К СИСТЕМЕ «ЧЕСТНЫЙ ЗНАК»
          </b-navbar-item>
          <b-navbar-item href="https://kkt57.ru/zamena-fiscalnogo-nakopitelya/">
            ЗАМЕНА ФИСКАЛЬНОГО НАКОПИТЕЛЯ
          </b-navbar-item>
          <b-navbar-item href="https://kkt57.ru/podkluchenie-k-ofd/">
            ПОДЛЮЧЕНИЕ К ОФД
          </b-navbar-item>
          <b-navbar-item href="https://kkt57.ru/shtrihkodirovanie_tovara/">
            ШТРИХКОДИРОВАНИЕ ТОВАРА
          </b-navbar-item>
          <b-navbar-item href="https://kkt57.ru/podklyuchenie-k-egais/">
            ПОДКЛЮЧЕНИЕ К СИСТЕМЕ ЕГАИС
          </b-navbar-item>
        </b-navbar-dropdown>
        <b-navbar-item tag="router-link" to="/"> КУПИТЬ </b-navbar-item>
        <b-navbar-item tag="a" href="https://kkt57.ru/vopros-otvet/">
          БЛОГ
        </b-navbar-item>
        <b-navbar-item tag="a" href="https://kkt57.ru/kontakti/">
          КОНТАКТЫ
        </b-navbar-item>
        <div
          class="headerphone"
          style="
            padding-left: 20px;
            padding-top: 8px;
            font-weight: bold;
            font-size: 18px;
            line-height: 32px;
          "
        >
          <b-icon icon="phone" size="is-medium" />
          <a href="tel:+79616266353">+7 (961) 626 63 53</a>
        </div>
      </template>
    </b-navbar>
  </div>
</template>


<script>
import Logo from "@/assets/logo.svg";
//import router from "@/router";
export default {
  data() {
    return {
      logo: Logo,
    };
  },
};
</script>

<style>
.header,
.navbar {
  background: #eaeaea !important;
}
.header a,
.navbar-item,
.navbar-item a:hover,
.router-link-active {
  color: #000000 !important;
  font-weight: bold !important;
}
.navbar-item:hover:not(:last-child) {
  background: #f7a31d !important;
}
.navbar-dropdown {
  background: #ffffff !important;
}
.navbar-link:hover {
  background: #f7a31d !important;
}
.navbar-dropdown a {
  padding: 0.8rem 1rem;
}
.navbar-brand .navbar-item:hover {
  background: #ffffff !important;
}
</style>