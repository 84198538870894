<template>
  <footer :class="classes">
    <div class="elementor-shape elementor-shape-top" data-negative="false">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1000 100"
        preserveAspectRatio="none"
        style="
          height: 65px;
          transform: translateX(-50%) rotateY(180deg);
          display: block;
          width: calc(100% + 1.3px);
          position: relative;
          left: 50%;
          margin-top: -1.1rem;
        "
      >
        <path
          class="elementor-shape-fill"
          d="M0,6V0h1000v100L0,6z"
          style="fill: #ffffff"
        ></path>
      </svg>
    </div>
    <div class="content has-text-centered">
      <div class="columns">
        <div class="column soc">
          <a
            href="https://t.me/kassa_servis"
            target="_blank"
            style="margin-right: 1rem"
          >
            <b-button
              size="large"
              type="is-info"
              style="background-color: #2980b9; width: 50px; height: 50px"
            >
              <b-icon icon="telegram" pack="fab" size="is-medium"></b-icon>
            </b-button>
          </a>
        </div>
        <div class="column">
          <p>Интернет Магазин. Касса Сервис © {{ year }}</p>
          <p>ИП Филиппов Андрей Александрович</p>
          <p>ИНН 575108955613</p>
          <p>
            <a href="/pdata.docx">Политика обработки персональных данных</a>
          </p>
        </div>
        <div class="column">
          <p><a href="tel:+79616266353">+7 961 626 63 53</a></p>
          <p>Приём звонков: 08:00-21:00</p>
          <p><a href="mailto:kkt57.ru@yandex.ru">kkt57.ru@yandex.ru</a></p>
          <p>Прием заявок: 24/7</p>
        </div>
      </div>
    </div>
  </footer>
</template>

<style scoped>
.footer {
  background-color: #fae3b7;
  padding: 1rem 0 1rem 0;
  width: 100%;
}
@media screen and (max-width: 900px) {
  .footer {
    padding: 1rem;
  }
  .elementor-shape {
    display: none;
  }
  .soc {
    display: block !important;
    text-align: left;
  }
}
p {
  margin: 0 !important;
  padding: 0 !important;
  text-align: left;
}
.soc {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: row;
}
</style>

<script>
export default {
  data() {
    return {
      year: new Date().getFullYear(),
      classes: ["footer"],
    };
  },
};
</script>